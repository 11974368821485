import React, { useContext, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Linking } from 'react-native';
import { AuthContext } from '../../components/AuthProvider';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

import LoginScreen from '../../screens/LoginScreen';
import PrivateScreen from '../../screens/PrivateScreen';
import Config from '../../config/config';

const AppNavigator = () => {
  const { user, setUser, setBrowseHistory, i18n } = useContext(AuthContext);

  const Screen = user ? PrivateScreen : LoginScreen;

  const config = {
    screens: {
      Profile: '',
      Login: 'login',
      CodeVerifying: 'code_verifying',
      PasswordVerifying: 'password_verifying',
      EmailCreating: 'email_creating',
      ResetPassword: 'reset_password',
      ResetPasswordUpdate: 'reset_password_update',
      Balance: 'balance',
      Trios: 'trios/:id?',
      Invitations: 'invitations/:id?',
      History: 'history',
      Transactions: 'transactions',
      PrivacyPolicy: 'privacy',
      About: 'about',
      Contacts: 'contacts',
      AdminMessages: 'admin_messages',
      FirstPage: 'welcome',
      ActivityDetails: 'request_details',
      RefOrders: 'referal_orders',
      Cashback: 'cashback',
      Moneybox: 'moneybox',
      MyOrders: 'my_orders',
      UserCashbackOrders: 'user_cashback_orders',
    },
  };

  const linking = {
    prefixes: [`${Config.API_URL}`],
    config,
  };

  // Получение части URL
  useEffect(() => {
    const loginUserByLink = async (link) => {
      try {
        await axios.get(
          `${Config.API_URL}/api/users/get_by_link/${link}`
        )
        .then(async (response) => {
          console.log('userByLink', response.data);
          if (response?.data?.message) {
            await AsyncStorage.setItem('user', JSON.stringify(response.data.message));
            setBrowseHistory([]);
            setUser(response.data.message);
          }
        });
      } catch(error) {
        console.log(error?.response?.data);
      }
    }

    const fetchInitialURL = async () => {
      if (!user) {
        try {
          const initialUrl = await Linking.getInitialURL();
          if (initialUrl) {
            const parsedUrl = new URL(initialUrl);
            const path = parsedUrl.pathname; // Получает путь после домена
            console.log('Initial Path:', path);
            loginUserByLink(path.substring(1));
          } else {
            console.log('No initial URL detected.');
          }
        } catch (error) {
          console.error('Error fetching initial URL:', error);
        }
      }
    };

    if (!user) {
      fetchInitialURL();
    }

    if (user && user.lang) {
      i18n.changeLanguage(user.lang);
    }
  }, [user, i18n, setUser, setBrowseHistory]);

  return (
    <NavigationContainer linking={linking}>
      <Screen />
    </NavigationContainer>
  );
};

export default AppNavigator;
